<template>
  <el-row v-if="report">
    <el-col :span="7">
      <h3
        class="mt-0">
        {{ $t('Статус отчёта') }}: {{ report.status_title }}
      </h3>
    </el-col>
    <el-col
      :span="17"
      align="right">
      <el-button
        id="report-edit"
        type="primary"
        :disabled="true || !reportEditable"
        @click="reportSave">
        {{ $t('Сохранить') }}
      </el-button>
      <el-button
        v-if="report.status.options.deletable"
        id="report-delete"
        type="danger"
        @click="reportDelete">
        {{ $t('Удалить') }}
      </el-button>
    </el-col>
  </el-row>
  <el-form
    :ref="dataForm.ref"
    v-loading="dataForm.loading"
    :model="dataForm.fields"
    label-position="top">
    <el-row :gutter="20">
      <el-col :span="8">
        <h3
          class="mt-10">
          {{ $t('Аппарат') }}
        </h3>
        <el-col
          :span="24"
          class="pl-0">
          <el-form-item
            prop="sound_device_id"
            :rules="dataForm.rules['sound_device_id']"
            label="Караоке-аппарат">
            <el-select
              v-model="currentSoundDevice"
              :remote-method="getKaraokeList"
              :disabled="edit"
              value-key="id"
              placeholder="Укажите номер аппарата"
              filterable
              remote
              reserve-keyword
              clearable
              @change="soundDeviceSelected">
              <el-option
                v-for="(item, key) in karaokeList"
                :key="key"
                :label="item.identifier"
                :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item
            prop="accumulated_statistics"
            :rules="dataForm.rules['accumulated_statistics']">
            <el-checkbox
              v-model="dataForm.fields.accumulated_statistics"
              disabled>
              {{ $t('Накопительная статистика') }}
            </el-checkbox>
          </el-form-item>
        </el-col>
      </el-col>
      <el-col :span="16">
        <h3
          class="mt-10">
          {{ $t('Объект') }}
        </h3>
        <ReportTableObjects
          :useobjects-data="useobjectsData"
          :edit="edit"
          @rowClicked="useobjectSelected" />
      </el-col>
    </el-row>
    <el-row
      :gutter="20"
      class="bb pb-10">
      <el-col
        :span="24">
        <ReportTableContracts
          :contracts-data="contractsData"
          :edit="edit"
          @rowClicked="contractSelected" />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="16">
        <ReportTableCalculations
          :calculations-data="calculationsData"
          :edit="edit"
          @checkChanged="changeCheckValue"
          @actualChanged="actual => actualChanged(actual)" />
      </el-col>
      <el-col
        :span="8"
        class="bl">
        <h3
          class="mt-10">
          {{ $t('Основная информация') }}
        </h3>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item
              :label="$t('Отчёт предоставлен')"
              prop="report_date"
              :rules="dataForm.rules['report_date']">
              <widget-date-picker
                v-model="dataForm.fields.report_date"
                mask="##.##.####"
                type="date"
                :placeholder="$t('Укажите дату')" />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item
              :label="$t('Сумма отчета')"
              prop="sum_report"
              :rules="dataForm.rules['sum_report']">
              <el-input
                id="sum_report"
                v-model="dataForm.fields.sum_report"
                class="disabled-bright"
                type="text"
                clearable
                style="width: 240px"
                :placeholder="$t('Не заполнено')"
                disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="18">
            <el-form-item
              :label="$t('Период отчета')"
              prop="period"
              :rules="dataForm.rules['period']">
              <widget-date-picker
                v-model="dataForm.fields.period"
                class="disabled-bright"
                mask="##.##.####"
                type="daterange"
                :start-placeholder="$t('Начало периода')"
                :end-placeholder="$t('Конец периода')"
                disabled="true"
                @change="changeDatePicker" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              :label="$t('Комментарий к отчету')"
              prop="comment"
              :rules="dataForm.rules['comment']">
              <el-input
                id="notes"
                v-model="dataForm.fields.notes"
                type="textarea"
                :placeholder="$t('Не заполнено')"
                :maxlength="$consts.maxWordLimitTextarea"
                show-word-limit />
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row
      :gutter="20"
      class="bt">
      <el-col :span="24">
        <h3
          class="mt-10">
          {{ $t('Файлы') }}
        </h3>
      </el-col>
      <el-col :span="8">
        <el-form-item
          prop="attached"
          :label="$t('Прикрепить файл отчета')"
          :rules="dataForm.rules['attached']">
          <widgetFileUpload
            v-model:attached="dataForm.fields.attached"
            v-model:detached="dataForm.fields.detached"
            :initial-files="files"
            :disabled="dataForm.fields.without_file"
            multiple />
        </el-form-item>
      </el-col>
      <el-col :span="16">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item
              prop="without_file"
              :rules="dataForm.rules['without_file']">
              <el-checkbox
                id="without_file"
                v-model="dataForm.fields.without_file"
                :label="$t('Распределение без файла')"
                :disabled="edit"
                @change="checkFilesEvents" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              v-if="dataForm.fields.without_file"
              :label="$t('Причина распределения без файла')"
              prop="without_file_reason"
              :rules="dataForm.rules['without_file_reason']">
              <el-input
                v-model="dataForm.fields.without_file_reason"
                type="text"
                clearable
                :disabled="edit || !!files.length" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row
      v-if="!report"
      :gutter="20">
      <el-col
        :span="24"
        align="right">
        <el-button @click="$emit('update:visible', false)">
          {{ $t('Отмена') }}
        </el-button>
        <el-button
          type="primary"
          @click="reportSave">
          {{ $t('Создать') }}
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { sortBy } from 'lodash'
import { REPORT, SOUND_DEVICE } from '@/api'
import { convertToFormData, replaceObjectValues, buildQueryObject } from '@/helps'
import widgetFileUpload from '@/components/widgets/WidgetFileUpload'
import WidgetDatePicker from '@/components/widgets/WidgetDatePicker'
import ReportTableObjects from './tables/ReportTableObjects'
import ReportTableContracts from './tables/ReportTableContracts'
import ReportTableCalculations from './tables/ReportTableCalculations'

export default {
  name: 'ReportForm',
  components: {
    widgetFileUpload,
    WidgetDatePicker,
    ReportTableObjects,
    ReportTableContracts,
    ReportTableCalculations
  },
  props: {
    report: {
      type: Object,
      default: () => null
    },
    visible: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['update:visible', 'reportSaved'],
  data () {
    return {
      karaokeList: [],
      useobjectsData: [],
      contractsData: [],
      calculationsData: [],
      currentUsobjectId: '',
      currentContractId: '',
      currentSoundDevice: undefined,
      status: undefined,
      reportEditable: false,
      actual: true,
      dataForm: {
        loading: false,
        ref: 'dataForm',
        fields: {
          id: undefined,
          type_id: 1,
          contract_id: undefined,
          useobject_id: undefined,
          sound_device_id: undefined,
          report_number: undefined,
          report_date: undefined,
          period_date_beg: undefined,
          period_date_end: undefined,
          accumulated_statistics: false,
          notes: '',
          sum_report: undefined,
          without_file: false,
          without_file_reason: '',
          accrual_ids: [],
          attached: [],
          detached: [],
          period: null
        },
        rules: {
          sound_device_id: {
            required: true,
            message: 'Не заполнено'
          },
          accumulated_statistics: {
            required: true,
            message: 'Не заполнено'
          },
          sum_report: {
            required: false,
            message: 'Не заполнено'
          },
          report_date: {
            required: true,
            message: 'Не заполнено'
          },
          period: {
            required: true,
            message: 'Не заполнено'
          },
          attached: {
            required: true,
            message: 'Прикрепите файлы отчетов'
          },
          without_file_reason: {
            required: false,
            message: 'Не заполнено'
          }
        }
      }
    }
  },

  computed: {
    edit () {
      return !!this.report.id
    },

    files () {
      const { attachments = [] } = this.report || {}
      return attachments
    },

    checkedCalculations () {
      return this.calculationsData.filter(calc => calc.checked)
    },

    checkedCalculationsSum () {
      return this.checkedCalculations.reduce((acc, cur) => acc + +(cur.useobject_scope.sum || 0), 0)
    },

    checkedCalculationsPeriod () {
      const [minPeriod = {}] = sortBy(this.checkedCalculations, [({ date_beg }) => new Date(date_beg)])
      const [maxPeriod = {}] = sortBy(this.checkedCalculations, [({ date_end }) => new Date(date_end)]).reverse()
      return [minPeriod.date_beg, maxPeriod.date_end]
    },

    checkedCalculationsIds () {
      return this.checkedCalculations.reduce((val, cur) => val.concat(cur.accrual_ids), [])
    },

  },

  created () {
    if (this.edit) {
      this.setReportData()
    }
  },

  mounted () {
  },

  methods: {
    async setReportData () {
      replaceObjectValues(this.dataForm.fields, this.report)
      this.dataForm.fields.period = [this.report.period_date_beg, this.report.period_date_end]
      if (this.report.sound_device) {
        this.status = this.report.status
        this.currentSoundDevice = this.report.sound_device
        this.karaokeList = [this.currentSoundDevice]
        this.setUseobjectsData(this.currentSoundDevice.id)
      }
      this.reportEditable = this.report.status?.options?.editable
    },

    reportSave () {
      this.$refs[this.dataForm.ref].validate((valid) => {
        if (valid) {
          this.dataForm.loading = true
          this.$http.post(REPORT[this.edit ? 'EDIT' : 'ADD'], convertToFormData(this.dataForm.fields))
            .then(() => {
              this.$emit('update:visible', false)
              this.$emit('reportSaved')
            })
            .finally(() => {
              this.dataForm.loading = false
            })
        }
      })
    },

    reportDelete () {
      const reportId = this.report.id
      if (reportId) {
        this.$confirm('Вы действительно хотите удалить отчет ?', 'Подтверждение', {
          confirmButtonText: 'Да',
          cancelButtonText: 'Отмена',
          type: 'warning'
        }).then(() => {
          this.$http.get(REPORT.DELETE, { params: { id: reportId }})
            .then(() => {
              this.$router.push({ name: 'ReportList' })
            })
            .finally(() => {
            })
        })
      }
    },

    getKaraokeList (val) {
      if (val && val.length <= 2) return
      const params = {
        f: {
          identifier: { ilike: val },
          systype_id: { eq: 2 }
        },
        scope: 'complete'
      }
      return this.$http.get(SOUND_DEVICE.LIST, { params }).then(r => {
        this.karaokeList = r.data.list
      })
    },

    attachFile (event) {
      const { files = [] } = event.target
      if (files.length) {
        this.dataForm.fields.attached.push(files[0])
      }
    },

    checkFilesEvents (val) {
      this.dataForm.fields.detached = []
      this.dataForm.fields.attached = []
      this.dataForm.rules.attached.required = !val
      this.dataForm.rules.without_file_reason.required = val
      if (!val) {
        this.$refs[this.dataForm.ref].clearValidate('without_file_reason')
      } else {
        this.$refs[this.dataForm.ref].clearValidate('attached')
      }
    },

    changeDatePicker (val) {
      if (val) {
        const [dateBeg, dateEnd] = val
        this.dataForm.fields.period_date_beg = dateBeg
        this.dataForm.fields.period_date_end = dateEnd
      } else {
        this.dataForm.fields.period_date_beg = null
        this.dataForm.fields.period_date_end = null
      }
    },

    soundDeviceSelected (soundDevice) {
      this.clearTablesData()
      if (soundDevice) {
        this.dataForm.fields.report_number = soundDevice.identifier
        this.dataForm.fields.sound_device_id = soundDevice.id
        this.setUseobjectsData(soundDevice.id)
        this.dataForm.fields.accumulated_statistics = ((soundDevice.options?.report_mode | 0) == 2)
      }
    },

    setUseobjectsData (soundDeviceId) {
      if (soundDeviceId) {
        const params = {
          sound_device_id: soundDeviceId
        }
        if (this.edit && this.report.useobject_id) {
          params.useobject_id = this.report.useobject_id
        }
        return this.$http.get(SOUND_DEVICE.USEOBJECTS, {params: params}).then(r => {
          this.useobjectsData = r.data.list
          if (this.edit) {
            this.currentUsobjectId = this.useobjectsData[0]?.id
            this.currentContractId = this.report.contract?.id
            this.contractsData = [this.report.contract]
            this.setCalculationsData(this.report.contract_id)
          } else {
            if (this.useobjectsData.length == 1) {
              this.useobjectSelected(this.useobjectsData[0]?.id)
            }
          }
        })
      }
    },

    useobjectSelected (useobjectId) {
      if (useobjectId) {
        this.currentUsobjectId = this.dataForm.fields.useobject_id = useobjectId
        this.setContractsData(useobjectId)
        this.clearCalculated()
      }
    },

    setContractsData (useobjectId) {
      if (useobjectId) {
        const params = {
          useobject_id: useobjectId,
          sound_device_id: this.dataForm.fields.sound_device_id
        }
        this.$http.get(SOUND_DEVICE.CONTRACTS, { params }).then(r => {
          this.contractsData = r.data.list
          if (!this.edit && this.contractsData.length == 1) {
            this.contractSelected(this.contractsData[0]?.id)
          }
        })
      }
    },

    contractSelected (contractId) {
      if (contractId) {
        this.currentContractId = this.dataForm.fields.contract_id = contractId
        this.setCalculationsData(contractId)
      }
    },

    actualChanged(actual) {
      const contractId = this.currentContractId
      if (contractId) {
        this.actual = actual
        this.setCalculationsData(contractId)
      }
    },

    setCalculationsData (contractId) {
      const sortData = { prop: 'date_beg', order: 'desc' }
      const params = {
        useobject_id: this.currentUsobjectId,
        sound_device_id: this.dataForm.fields.sound_device_id,
        contract_id: contractId,
        has_not_reports: Number(this.edit ? false : this.actual),
        ...buildQueryObject(sortData)
      }
      if (this.edit && this.dataForm.fields.id) {
        params.report_id = this.dataForm.fields.id
      }
      this.clearCalculated()
      this.$http.get(SOUND_DEVICE.ACCRUALS, { params }).then(r => {
        this.calculationsData = r.data.list.map(calc => ({ ...calc, checked: false }))
      })
    },

    clearTablesData () {
      this.useobjectsData = []
      this.currentUsobjectId = ''
      this.contractsData = []
      this.currentContractId = ''
      this.calculationsData = null
    },

    clearCalculated () {
      if (!this.edit) {
        this.dataForm.fields.sum_report = null
        this.dataForm.fields.period = null
        this.calculationsData = null
      }
    },

    changeCheckValue (scope) {
      if (!this.edit) {
        this.calculationsData[scope.$index].checked = scope.row.checked
        this.dataForm.fields.sum_report = this.checkedCalculationsSum
        this.dataForm.fields.period = this.checkedCalculationsPeriod
        this.dataForm.fields.accrual_ids = this.checkedCalculationsIds
        this.changeDatePicker(this.checkedCalculationsPeriod)
      }
    }
  }
}
</script>
<style >
.disabled-bright input {
  color: var(--el-input-text-color,var(--el-text-color-regular)) !important;
}
</style>
