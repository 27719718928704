<template>
  <h3
    class="mt-10">
    {{ $t('Начисления') }}
  </h3>
  <BaseActualSort
    v-if="!edit"
    @actualChanged="(actual) => $emit('actualChanged', actual)" />
  <el-table
    :data="calculationsData"
    :default-sort="{ prop: 'date_beg', order: 'descending' }"
    max-height="300"
    border
    stripe
    scrollbar-always-on
    @sort-changed="s => $emit('sortChanged', s)">
    <el-table-column
      width="50"
      align="center">
      <template #default="scope">
        <el-checkbox
          v-if="checkableRow(scope.row)"
          id="calculation-period-checkbox"
          v-model="scope.row.checked"
          @change="$emit('checkChanged', scope)" />
      </template>
    </el-table-column>
    <el-table-column
      prop="date_beg"
      label="Период"
      align="center"
      sortable="custom">
      <template #default="scope">
        {{ $filters.convertDate(scope.row.date_beg) }} - {{ $filters.convertDate(scope.row.date_end) }}
      </template>
    </el-table-column>
    <el-table-column
      prop="sum_payment"
      label="Начислено всего"
      align="right">
      <template #default="scope">
        {{ $filters.convertPrice(scope.row.sum) }}
      </template>
    </el-table-column>
    <el-table-column
      prop="sum_payment"
      label="Оплачено"
      align="right">
      <template #default="scope">
        {{ $filters.convertPrice(scope.row.sum_paid) }}
      </template>
    </el-table-column>
    <el-table-column
      prop="sum_accrual_obj"
      label="Начисл. объект"
      align="right">
      <template #default="scope">
        {{ $filters.convertPrice(scope.row.useobject_scope.sum) }}
      </template>
    </el-table-column>
    <el-table-column
      prop="sum_reported_obj"
      label="Закр. отчетами"
      align="right">
      <template #default="scope">
        {{ $filters.convertPrice(scope.row.useobject_scope.sum_report) }}
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import BaseActualSort from '@/components/base/BaseActualSort'
export default {
  components: {
    BaseActualSort
  },
  props: {
    calculationsData: {
      type: Array,
      default: () => []
    },
    edit: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['checkChanged', 'sortChanged', 'actualChanged'],
  setup () {
    const sortChanged = (actual) => {
    }
    return {
      sortChanged
    }
  },
  methods: {
    checkableRow (row) {
      return !this.edit && (row.useobject_scope.sum_report == 0)
    }
  }
}
</script>
