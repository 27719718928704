<template>
  <h3
    class="mt-10">
    {{ $t('Договор') }}
  </h3>
  <el-table
    :data="contractsData"
    :highlight-current-row="contractsData?.length > 1"
    max-height="400"
    border
    stripe
    scrollbar-always-on
    @row-click="onRowClick">
    <el-table-column
      label="Номер договора"
      align="center">
      <template #default="scope">
        <!-- <router-link :to="{ name: 'Contract', params: { id: scope.row.id } }"> -->
        {{ scope.row.contract_number }}
        <!-- </router-link> -->
      </template>
    </el-table-column>
    <el-table-column
      label="Дата договора"
      align="center">
      <template #default="scope">
        {{ $filters.convertDate(scope.row.contract_date) }}
      </template>
    </el-table-column>
    <el-table-column
      prop="status.title"
      label="Статус договора"
      align="center" />
    <el-table-column
      label="Субъект"
      align="center">
      <template #default="scope">
        <!-- <router-link :to="{ name: 'Subject', params: { id: scope.row.subject_id } }"> -->
        {{ scope.row.subject.title }}
        <!-- </router-link> -->
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  name: 'ReportTableContracts',
  props: {
    contractsData: {
      type: Array,
      default: () => []
    },
    edit: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['rowClicked'],
  mounted () {
  },
  methods: {
    onRowClick ({ id }) {
      if (!this.edit) {
        this.$emit('rowClicked', id)
      }
    }
  }
}
</script>
