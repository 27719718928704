<template>
  <el-table
    ref="useobjectsTable"
    :data="useobjectsData"
    :highlight-current-row="useobjectsData?.length > 1"
    max-height="300"
    border
    stripe
    scrollbar-always-on
    @row-click="onRowClick">
    <el-table-column
      label="Объект"
      align="left">
      <template #default="scope">
        <router-link
          target="_blank"
          :to="{ name: 'ObjectItem', params: { id: scope.row.id } }">
          {{ scope.row.title }}
        </router-link>
      </template>
    </el-table-column>
    <el-table-column
      label="Период действия аппарата на объекте"
      align="left"
      width="350">
      <template #default="scope">
        <p
          v-for="({date_beg, date_end, id}) in scope.row.related_periods"
          :key="id">
          {{ $filters.convertDate(date_beg) }} - {{ date_end ? $filters.convertDate(date_end) : '...' }}
        </p>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  name: 'ReportTableObjects',
  props: {
    useobjectsData: {
      type: Array,
      default: () => []
    },
    edit: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['rowClicked'],

  watch: {
    useobjectsData (val) {
      if (this.edit && val.length) {
        const [useobject] = this.useobjectsData
        this.$refs.useobjectsTable.setCurrentRow(useobject)
      }
    }
  },

  mounted () {
  },

  methods: {
    onRowClick ({ id }) {
      if (!this.edit) {
        this.$emit('rowClicked', id)
      }
    }
  }
}
</script>
