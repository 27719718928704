<template>
  <el-radio-group
    v-model="actual"
    @change="$emit('actualChanged', actual)">
    <el-radio
      id="actual-sort"
      :label="true">
      {{ $t('Актуальные') }}
    </el-radio>
    <el-radio
      id="all-sort"
      :label="false">
      {{ $t('Все') }}
    </el-radio>
  </el-radio-group>
</template>
<script>
import { ref } from 'vue'
export default {
  props: {
  },
  emits: ['actualChanged'],
  setup () {
    const actual = ref(true)
    return {
      actual
    }
  }
}
</script>
