<template>
  <el-upload
    ref="fileList"
    class="upload-demo"
    action="/api/"
    multiple
    :auto-upload="false"
    :on-preview="onFilePreview"
    :on-remove="onFileRemove"
    :limit="limit"
    :file-list="filesList"
    :on-change="updateAttachedFiles"
    :disabled="disabled">
    <el-button
      id="gvc0wxrld"
      type="primary"
      :disabled="disabled">
      {{ 'Загрузить' }}
    </el-button>
  </el-upload>
</template>
<script>

export default {
  name: 'FileUploadItem',
  props: {
    initialFiles: {
      type: Array,
      default: () => []
    },
    attached: {
      type: Array,
      default: () => []
    },
    detached: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    limit: {
      type: Number,
      default: () => 20
    }
  },
  emits: ['update:detached', 'update:attached'],

  data () {
    return {
      filesList: [],
      detachedFilesIds: []
    }
  },

  watch: {
    initialFiles (initialFiles) {
      if (!initialFiles) {
        this.filesList = []
      }
    }
  },

  created () {
    this.initFilesList()
  },

  methods: {
    initFilesList () {
      this.filesList = this.initialFiles ? this.initialFiles.map(file => Object.assign({ name: file.filename }, file)) : []
    },

    updateAttachedFiles (file, fileList) {
      const attachedFiles = fileList.filter(file => !file.id).map(file => file.raw)
      this.$emit('update:attached', attachedFiles)
    },

    onFilePreview (file) {
      /* Http.files.getUrl({ id: file.id }).then(response => {
        window.location = response.data.url
      }) */
    },

    onFileRemove ({ id }, fileList) {
      if (id) {
        this.detachedFilesIds.push(id)
        this.$emit('update:detached', this.detachedFilesIds)
      } else {
        this.updateAttachedFiles(null, fileList)
      }
    }
  }
}
</script>

<style lang="scss">
  .upload-demo {
    max-width: 100%;
  }
  .el-upload-list {
    &__item-name {
      text-overflow: ellipsis;
    }
  }
</style>
