<template>
  <div v-mask="props.mask">
    <el-date-picker
      v-model="value"
      class="full-width"
      :type="props.type"
      format="DD.MM.YYYY"
      value-format="YYYY-MM-DD"
      v-bind="placeholderComputed"
      :disabled="disabled" />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  mask: {
    type: String,
    default: '##.##.####'
  },
  modelValue: {
    type: [String, Array],
    default: () => ''
  },
  type: {
    type: String,
    default: 'date'
  },
  placeholder: {
    type: String,
    default: 'Не заполнено'
  },
  startPlaceholder: {
    type: String,
    default: 'Начало'
  },
  endPlaceholder: {
    type: String,
    default: 'Конец'
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue', 'change'])
const value = computed({
  get () {
    return props.modelValue
  },
  set (value) {
    emit('update:modelValue', value)
    emit('change', value)
  }
})

const { t } = useI18n()
const placeholderComputed = computed(() => {
  if (props.type === 'date') {
    return {
      placeholder: t(props.placeholder)
    }
  }
  return {
    'start-placeholder': t(props.startPlaceholder),
    'end-placeholder': t(props.endPlaceholder)
  }
})

</script>
